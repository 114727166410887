/* ConfirmationDialog.css */

.dialog {
  text-align: center;
}

.title {
  background-color: #001F3F;
  color: #fff;
  padding: 15px;
  font-size: 1.5rem;
}

.content {
  padding: 20px;
  font-size: 1.2rem;
  color: #333;
}

.actions {
  padding: 15px;
  display: flex;
  justify-content: center;
}

/* ConfirmationDialog.css */

/* Override Material-UI styles for the .cancelButton */
.MuiButton-root.cancelButton {
  color: #333;
  background-color: #ddd;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  /* Add a smooth transition */
}

/* Hover effect for .cancelButton */
.MuiButton-root.cancelButton:hover {
  background-color: #ccc;
}

/* Override Material-UI styles for the .confirmButton */
.MuiButton-root.confirmButton {
  color: #fff;
  background-color: #f44336;
  /* Material-UI's error color */
  border-color: #f44336;
  transition: background-color 0.3s ease;
  /* Add a smooth transition */
}

/* Hover effect for .confirmButton */
.MuiButton-root.confirmButton:hover {
  background-color: #d32f2f;
  /* Darken the color on hover */
}
