/* AboutUs.css */

.aboutUsContainer {
  text-align: center;
  accent-color: #ff4081;
}

.aboutUsCard {
  background-color: #f2f2f2 !important;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 70%;
  margin: auto;
}

.aboutUsTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
  accent-color: #ff4081;
}

.aboutUsParagraph {
  font-size: 1.2rem;
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  color: #333;
  accent-color: #ff4081;
}

.aboutUsImage {
  width: 100%;
  border-radius: 10px;
  margin-right: 30px;
  margin-left: 30px;
  max-height: auto;
  max-width: 200px;
  object-fit: cover;
  accent-color: #ff4081;
}

.aboutUsImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  accent-color: #ff4081;
  width: 90%;

  img {
    margin: 0 8px;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .aboutUsCard {
    max-width: 100%;
  }

  .aboutUsImage {
    margin-right: 0;
    margin-left: 0;
  }
}
