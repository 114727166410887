/* AddPetButton.css */

.MuiButton-root.addButton {
  background-color: #D2691E;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

.MuiButton-root.addButton:hover {
  background-color: #D2691E;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
