/* BookingCard.css */

.card {
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(to bottom, #E8F4FF, #BFD3C1);
}

.card:hover {
  transform: scale(1.02);
}

.serviceName {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.petChip {
  margin-right: 4px;
  margin-bottom: 4px;
}
