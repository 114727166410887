/* MyBookings.css */

.container {
  margin-top: auto;
  padding: 3rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.addButton {
  margin-top: 2rem;
}

.bookingsGrid {
  margin-top: 2rem;
}

/* BookingCard styles */
.card {
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(to bottom, #e8f4ff, #bfd3c1);
}

.serviceName {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.petChip {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
