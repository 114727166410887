@import url('https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Proxima Nova', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#fff5f8';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-list-container {
  width: 90%;
  margin: 0 auto;
  /* Center the container horizontally */
}

/* styles.css */

.map-container {
  max-width: 65%;
  height: 400px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 600px) {
    max-width: 95%;
  }
}
