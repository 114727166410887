/* MyPets.css */

.petsContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* Adjust the values as needed */
  grid-gap: 16px;
  /* Adjust the gap between cards */
  justify-content: space-evenly;
  /* Adjust to your preference */
}


/* You can add more styles as needed for your component */
