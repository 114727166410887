/* MyPetsPage.css */

.container {
  margin-top: 32px;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
}

.addBtnContainer {
  margin-top: 16px;
}

.userPetsContainer {
  margin-top: 16px;
}

.loginPrompt {
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 8px;
  text-align: center;
}

.loginPromptText {
  margin-bottom: 8px;
  color: #555;
}
