/* PetCard.css */

.card {
  margin-bottom: 16px;
  margin-right: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(to bottom, #E8F4FF, #BFD3C1);

}

.editTextField {
  margin-bottom: 16px;
}

/* You can add more styles as needed for your component */
