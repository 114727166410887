/* TitlebarImageList.css */

.image-list-container {
  width: 100%;
  display: grid;
  gap: 10px;

}

.image-list-container .MuiImageList-root li.MuiImageListItem-root {
  grid-column-end: span 1 !important;
  grid-row-end: span 1 !important;


}

.image-list-container .MuiListSubheader-root {
  color: #001F3F;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  background: #87CEEB;
}

.image-list-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.image-list-container .MuiImageListItemBar-root {
  background: #87CEEB;
  text-align: center;
  color: #001F3F;
  font-family: '-apple-system';
}

.image-list-container .MuiImageListItemBar-title {
  color: #001F3F !important;
  font-weight: bold;
}

.image-list-container .MuiTypography-root {
  text-align: center;
  color: white;
  font-family: 'Open Sans', sans-serif;
}


li.MuiImageListItem-root.MuiImageListItem-standard.photo-subheader {
  grid-column: 1 / -1 !important;
  grid-row-end: span 1 !important;
}

.image-list-container .MuiImageList-root li.MuiImageListItem-root {
  grid-column-end: span 1;
  grid-row-end: span 1 !important;
}

.image-list-container .MuiImageList-root {
  width: 100%;
  height: auto;
  display: grid;
  /* Add display: grid to handle internal items */
  /* Set internal grid to 4 columns */
}

@media (min-width: 600px) {

  .image-list-container .MuiImageList-root {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

/* Mobile Phones */
@media (max-width: 599px) {
  .image-list-container .MuiImageList-root {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

/* Small Tablets and Larger Phones */
@media (min-width: 600px) and (max-width: 959px) {
  .image-list-container .MuiImageList-root {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

/* Tablets and Small Laptops */
@media (min-width: 960px) and (max-width: 1279px) {
  .image-list-container .MuiImageList-root {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

/* Laptops and Desktops */
@media (min-width: 1280px) {
  .image-list-container .MuiImageList-root {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}


.image-list-container .MuiListSubheader-root {
  color: #001F3F;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  background: #87CEEB;
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

.image-list-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.image-list-container .MuiImageListItemBar-root {
  background: #87CEEB;
  text-align: center;
  color: white;
  font-family: '-apple-system';
}

.image-list-container .MuiTypography-root {
  text-align: center;
  color: white;
  font-family: 'Open Sans', sans-serif;
}
