/* Footer.css */

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  background-color: #001F3F;
  padding: 20px 0;
  color: #FFFFFF;
  margin-top: auto;
}

.text {
  background: #001F3F;
  color: #FFFFFF;
}

.linkText {
  text-decoration: none;
  color: #66B2FF !important;
}

.linkText:hover {
  text-decoration: underline;
  color: #3399FF !important;
}
